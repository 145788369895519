import React from 'react'
import "./services.css"
import {AiOutlineCheck} from 'react-icons/ai'

const Serivices = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Charming Website Designs</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>CSS Animations</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Cart Of Different Styles</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Modern Web Templates</p>
            </li>
          </ul>
        </article>
        {/* =================== END OF UI/UX DESIGN =============== */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development Backend</h3>
          </div>

          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Django Web Development</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>API Creation With Rest Technology(DRF)</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Python Scripting</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Python Scraping</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>Database(PostgreSQL, MySQL, SQLite, ElasticSearch)</p>
            </li>
          </ul>
        </article>
        {/* =================== END OF WEBDEVELOPMENT DESIGN =============== */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development Frontend</h3>
          </div>

          <ul className="service__list">
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>HTML</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>CSS</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>JavaScript</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>ReactJS</p>
            </li>
            <li>
              <AiOutlineCheck className="service__list-icon" />
              <p>MUI</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Serivices