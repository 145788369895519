import React from 'react'
import "./portfolio.css"
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG6 from '../../assets/portfolio6.jpg'
import IMG5 from '../../assets/portfolio5.png'

const Portfolio = () => {
  return (
    <section className="portfolio" id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG1} alt="IMG1" />
          </div>
          <h3>Scraping Project That Scrap Data from Stock Website</h3>
          <div className="portfolio__item-cta">
            <a href='https://github.com/AliCheema77/marketwatch_scraping.git  ' className='btn' target='_blank'>Github</a>
            {/* <a href='www.examble.com' className='btn btn-primary' target='_blank'>Live Demo</a> */}
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG2} alt="IMG1" />
          </div>
          <h3>DRF Project </h3>
          <div className="portfolio__item-cta">
            <a href='https://github.com/AliCheema77/movie_backend.git' className='btn' target='_blank'>Github</a>
            {/* <a href='www.examble.com' className='btn btn-primary' target='_blank'>Live Demo</a> */}
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG3} alt="IMG1" />
          </div>
          <h3>Ride Service Website</h3>
          <div className="portfolio__item-cta">
            <a href='https://github.com/AliCheema77/Uber-demo.git' className='btn' target='_blank'>Github</a>
            {/* <a href='www.examble.com' className='btn btn-primary' target='_blank'>Live Demo</a> */}
          </div>
        </article>

        {/* <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG4} alt="IMG1" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portfolio__item-cta">
            <a href='www.examble.com' className='btn' target='_blank'>Github</a>
            <a href='www.examble.com' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG5} alt="IMG1" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portfolio__item-cta">
            <a href='www.examble.com' className='btn' target='_blank'>Github</a>
            <a href='www.examble.com' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG6} alt="IMG1" />
          </div>
          <h3>This is a portfolio item title</h3>
          <div className="portfolio__item-cta">
            <a href='www.examble.com' className='btn' target='_blank'>Github</a>
            <a href='www.examble.com' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article> */}
      </div>
    </section>
  )
}

export default Portfolio