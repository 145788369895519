import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {BsDribbble} from 'react-icons/bs'


const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href='https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile' target={'_blank'}><BsLinkedin /></a>
      <a href='https://github.com/AliCheema77' target={'_blank'}><FaGithub /></a>
      <a href='https://dribbble.com/AliCheema77' target={'_blank'}><BsDribbble /></a>
    </div>
  )
}

export default HeaderSocials