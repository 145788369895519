import React from 'react'
import './footer.css'
import {BsFacebook} from 'react-icons/bs'
import {FaInstagramSquare} from 'react-icons/fa'
import {AiOutlineSkype} from 'react-icons/ai'


const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>Ali Cheema</a>

      <ul className="permalinks">
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href='https://www.facebook.com/aliasadullah.cheema' target={'_blank'}><BsFacebook /></a>
        <a href='https://www.instagram.com/aliasadullahcheema/' target={'_blank'}><FaInstagramSquare /></a>
        <a href='https://join.skype.com/invite/wiul1aXyqrA5' target={'_blank'}><AiOutlineSkype /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Ali Cheema</small>
      </div>
    </footer>
  )
}

export default Footer