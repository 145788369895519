import React from 'react'
import "./testimonials.css"
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// import Swiper core and required modules
import {Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: "",
    name: "Shibur R.",
    review: `I loved working with Ali Cheema, I enjoyed working on this project.
    All expectations and requirements were communicated responsive to 
    any of my requests for clarification. I liked working with him and
    hope to have the opportunity to work with him again. `
  },
  {
    avatar: "",
    name: " Evgeni SK",
    review: ` It was amazing working with him he did a excellent job one of 
    the best at what he does and I have worked with many freelancers. I did 
    ask others and he was the only one who could complete the job and he did 
    it fast and beyond my expectation! I highly recommend you to hire him you 
    will not be disappointed!`
  },
  {
    avatar: "",
    name: " Yuras S.",
    review: `It's always a pleasure to work with him. Thanks again.`
  },
  {
    avatar: "",
    name: "Tina K Shaw",
    review: `Great man, nice conversation. Really helpful attitude, looking forward to working together again!`
  }
]

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {
          data.map(({avatar, name, review}, index) => {
            return(
              <SwiperSlide className="testimonial" key={index}>
                <div className="client__avatar">
                  <img src={avatar} alt="" />
                </div>
                <h5 className='client__name'>{name}</h5>
                <small className="client__review">{review}</small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials